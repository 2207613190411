.PressReleases {
  position: relative;
  text-align: center;

  &.showingVideo {
    z-index: 1 !important;
  }

  h2 {
    text-transform: uppercase;
    font-weight: bold;
  }

  > h2 {
    letter-spacing: 5px;
  }

  h4 {
    text-align: left;
  }

  a {
    color: var(--green);
  }

  p {
    font-family: JDSansPro;
  }

  @media screen and (max-width: 768px) {
    h4,
    p {
      width: 80%;
      margin: 0 auto;
    }
  }

  .Presenter {
    font-weight: bold;
  }
}

.PressReleaseExpander {
  max-width: 1200px;
  margin: 1rem auto;
  text-align: left;
  position: relative;
  border-left: 3px solid transparent;
  border-color: #415813;

  @media screen and (max-width: 1024px) {
    width: 90%;
  }

  @media screen and (max-width: 768px) {
    h2 {
      font-size: 1.3rem;
    }
  }

  .HeaderBar {
    background-color: #eee;
    padding: 1rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding-right: 3rem;
    cursor: pointer;
    font-size: 1.25rem;
    position: relative;
    font-weight: bold;
    font-family: JDSansProCond;

    &:hover {
      background-color: #dddddd;
    }

    @media screen and (max-width: 1024px) {
      span {
        font-size: 1rem;
        &.Splitter.Pipe {
          display: block;
          height: 0;
          overflow: hidden;
        }
      }
    }

    @media screen and (max-width: 440px) {
      span {
        display: block;
        &.Splitter {
          display: none;
        }
        font-size: 0.8rem;
      }
    }
  }

  .Body {
    background-color: #fafafa;
    text-align: center;
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.3s ease-in;

    p {
      font-size: 18px;
    }

    h4,
    h2,
    p,
    ul {
      max-width: 780px;
      margin: 1rem auto;
      text-align: left;
    }
    ul {
      list-style-type: none;
      li {
        text-indent: -12px;

        &:before {
          content: "– ";
          text-indent: -20px;
        }
      }
    }

    h2 {
      text-align: center;
      margin-bottom: 30px;
    }

    .Accolades .badges h4 {
      text-align: center;
    }

    &.expanded {
      height: auto;
      padding: 2rem;
      opacity: 1;
      transition: opacity 0.3s ease-in;

      @media screen and (max-width: 768px) {
        padding: 1rem;
      }
    }
  }

  .Expander {
    font-size: 2.5rem;
    color: var(--green);
    font-family: JDSansPro;
    font-weight: normal;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    line-height: 1;
    cursor: pointer;

    &::after {
      content: "+";
    }

    &.expanded {
      transform: translateY(-60%);
      &::after {
        content: "–";
      }
    }

    @media screen and (max-width: 768px) {
      // right: 0;
    }
  }

  .Accolades {
    background-image: none;
    background-color: transparent;
  }
}
