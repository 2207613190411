.VideoOverlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s ease;
  z-index: -1;

  &.show {
    opacity: 1;
    transition: opacity 0.5s ease;
    z-index: 70000;
  }

  .iframe-container {
    // overflow: hidden;
    padding-top: 35%;
    position: relative;
    width: 50%;

    svg {
      position: absolute;
      top: 5px;
      right: -45px;
      height: 30px;
      width: 30px;
      cursor: pointer;
      * {
        fill: white;
      }
    }

    @media screen and (max-width: 1024px) {
      width: 90%;
      padding-top: 56.25%;

      svg {
        right: 5px;
        top: -30px;
      }
    }
  }

  .iframe-container iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
