header {
  height: 79px;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  text-align: left;
  font-family: JDSansProCond;

  img {
    max-height: 100%;
  }

  .AppMenu {
    position: absolute;
    top: 0;
    right: 0;
    list-style-type: none;
    font-weight: bold;

    .menu {
      margin: 0;
      height: 80px;
      display: flex;
      align-items: center;
    }

    .menu-toggle {
      display: none;
      position: relative;
      height: 30px;
      width: 30px;
      span {
        display: block;
        margin-bottom:4px;
        height: 4px;
        width:30px;
        background: var(--green);
        border-radius: 9px;
        transition: transform 0.2s ease;

      }

      &.open {
        span {
          position: absolute;
          top: 25%;
          left: 0;
          &:first-child {
            transition: transform 0.2s ease;
            transform: rotate(45deg);
          }
          &:last-child {
            transition: transform 0.2s ease;
            transform: rotate(-45deg);
          }
          &:not(:first-child):not(:last-child) {
            display: none;
          }
        }
      }
    }

    @media screen {
    }
    ul {
    }

    li {
      display: inline-block;

      &.active {
        a {
          color: var(--green);
        }
      }
    }

    a {
      display: block;
      padding: 0.8rem 1.5rem;
      text-transform: uppercase;
      text-decoration: none;
      color: gray;
      font-size: 1.2rem;
      &:hover {
        color: var(--green);
      }
    }

    @media screen and (max-width: 1333px) {
      a {
        font-size: 1rem;
        padding: 0.8rem 1rem;
      }
    }

    @media screen and (max-width: 1136px) {
      .home { display: none; }
    }

    @media screen and (max-width: 1020px) {

      .AppMenu {
        float: none;
      }
      .menu-toggle {
        display: block;
        position: fixed;
        top: 15px;
        right: 15px;
        color: black;
      }
      .menu {
        // display: none;
        margin: 50px 0 0 0;
        padding: 0;
        opacity: 0;
        // z-index: -1;
        position: fixed;
        height: 0;

        &.open {
          height: auto;
          display: block;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          background-color: var(--green);
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          opacity: 1;
          transition: opacity 0.5s ease;
          z-index: 1;

          li {
            display: block;
            background-color: transparent;
            a {
              font-size: 2rem;
              color: white;
              padding: 0.8rem 1rem;
            }
          }
        }

      }

    }
  }

  @media screen and (max-width: 1020px) {
    height: 50px;

    img {
      width: 212px;
    }
  }
}
