body {
  overflow-x: hidden;
}

.PostPage2023 {
  margin-top: 40px;

  .page-intro {
    font-weight: 700;
    font-size: 28px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-family: JDSansProCond;
  }

  .HeroLiveStream {
    margin-top: 3rem;
    max-width: 1366px;
    margin-left: auto;
    margin-right: auto;
    h2 {
      font-size: 3rem;
    }
    height: auto;
    min-height: 0;

    padding: 4vw 12vw 0;

    @media screen and (max-width: 768px) {
      &__content {
        margin: 0;
      }

      h2 {
        font-size: 1.5rem;
      }
    }
  }
  .DateTimeCallout {
    background-image: url("./img/2023/sprayer.jpg");
    background-size: cover;
    height: 34.5vw;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: auto;
    max-width: none;
    max-height: 650px;
    margin-top: 60px;

    h2 {
      color: white;
      text-transform: uppercase;
      font-family: JDSansProCond;
      font-size: 72px;
      font-weight: 800;
      margin: 0 auto;
    }

    &__content {
      text-align: center;
    }

    &__copy {
      text-align: center;
      margin: 0 auto;
      font-family: JDSansProCond;
      font-weight: 300;
      font-size: 2rem;
      margin-top: 2rem;
    }

    @media screen and (max-width: 768px) {
      height: auto;
      max-height: none;
      background-image: url("./img/2023/sprayer-mobile.png");
      padding-top: 80vw;
      width: 100%;
      background-position: top center;
      background-size: 100%;
      background-repeat: no-repeat;

      &__content,
      &__copy {
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
      }

      h2 {
        color: var(--green);
        text-align: center;
        line-height: 1;
        margin: 0;
      }
    }

    @media screen and (max-width: 370px) {
      h2 {
        font-size: 48px;
      }
    }
  }
}

.FancyHr {
  height: 2px;
  max-width: 455px;
  margin: 3rem auto;
  background: transparent
    linear-gradient(
      90deg,
      #5cafe7 0%,
      #d1ba6e 9%,
      #82c289 16%,
      #d0ba6e 24%,
      #5bb0e9 33%,
      #7ec28b 46%,
      #61b3d4 58%,
      #d2ba6d 68%,
      #90bf83 80%,
      #5aafea 89%,
      #7dc18e 100%
    )
    0% 0% no-repeat padding-box;
}

.EECallout {
  background-image: url("./img/2023/ElectricExcavator_BG-2x.jpg");
  // background-size: cover;
  background-position: 20% 0;
  height: 34.5vw;
  min-height: 600px;
  margin: 0 auto;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width: 90%;
  max-width: 1200px;
  max-height: 450px;
  margin-top: 80px;
  margin-bottom: 60px;

  &__content {
    width: 50%;
    flex-grow: 1;
    backdrop-filter: blur(14px) brightness(0.4);
    height: 100%;
    padding: 5% 6% 0 6%;
    color: white;
    box-sizing: border-box;
    font-family: JDSansProCond;

    h2 {
      font-size: 40px;
      font-weight: bold;
      margin: 0;
    }

    h3 {
      font-size: 30px;
      font-family: JDSansProCond;
      font-weight: bold;
      text-align: left;
      margin: 0;
    }

    p {
      text-align: left;
    }
  }

  @media screen and (max-width: 1024px) {
    &__content {
      padding: 3%;
    }
  }

  @media screen and (max-width: 768px) {
    height: auto;
    flex-direction: row;
    margin-top: 40px;
    background-image: url("./img/2023/ElectricExcavator_BG-2x.jpg");
    padding-top: 65vw;
    @media screen and (max-width: 370px) {
      padding-top: 115vw;
    }
    background-position: top center;
    background-size: 100%;
    background-repeat: no-repeat;

    .KeynoteCallout__content {
      padding: 5% 0;
      color: black;
      backdrop-filter: none;

      &,
      h2,
      h3,
      p {
        text-align: center;
      }
    }
  }
}

.big-green {
  font-size: 47px;
  color: var(--green);
  font-weight: bold;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }
}

.PadPanel {
  @media screen and (max-width: 768px) {
    padding: 0 2rem;
  }
}

.PipeJoined {
  .PipeJoined__pipe {
    &::after {
      content: "|";
      margin: 0 1rem;
    }

    @media screen and (max-width: 768px) {
      display: block;

      &::after {
        content: "";
        margin: 0;
      }
    }
  }
}
