$gray: #ddd;
$lightgray: #eee;

.FactSheet2Page {
  .pageheader {
    background-image: url("./img/FactSheet_Header-Background.jpg");
    text-align: center;
    padding: 5rem 0;
    color: white;
    font-size: 4rem;
    font-weight: bold;
    text-transform: uppercase;
    font-family: JDSansPro;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    > div {
      display: inline-block;
      border: 1px solid white;
      padding: 20px 5px 10px 5px;
      margin-left: 30px;

      svg {
        // width: 30px;
        height: 60px;
        margin: 0;
      }
    }

    @media screen and (max-width: 960px) {
      // font-size: 2rem;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      > div {
        margin-top: 10px;
        // padding: 0;
        // width: 60%;
        display: flex;
        flex-direction: row;
        padding-bottom: 20px;
        margin-left: 0;

        svg {
          height: 50px;
          width: 280px;
        }
      }
    }
  }

  .page-content {
    padding: 80px 0;
    max-width: 1200px;
    margin: 0 auto;

    @media screen and (max-width: 1024px) {
      padding: 50px 0;
    }
  }

  .fact-content {
    font-size: 18px;
    line-height: 1.2;
    a {
      font-style: italic;
      color: black;
    }
  }

  h3 {
    font-size: 2rem;
    text-align: center;
    padding: 3rem 0 3rem;
    margin: 0;
    text-transform: uppercase;

    @media screen and (max-width: 960px) {
      font-size: 1.5rem;
    }

    @media screen and (max-width: 600px) {
      margin: 1rem 0;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin: 0 0 1.5rem 0;
    }
  }

  .attrib {
    font-size: 0.8em;
  }

  .div1 {
    background-color: $lightgray;
    text-align: center;
    background-image: url("./img/CES-FactSheet-Icon_Globe.svg");
    background-size: cover;
    background-position: center center;
    padding: 0 3rem 3rem 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 16px;
    svg {
      width: 30px;
      margin: 0 0.35rem;

      @media screen and (max-width: 960px) {
        width: 20px;
      }
    }

    .fact-content {
      margin-top: 20px;
      max-width: 760px;
    }
    .icon {
      display: flex;
      align-items: center;
      font-size: 30px;
      margin: 0 auto;
      justify-content: center;
      div.big-separator {
        margin: 0 0.75rem;
      }
    }
  }

  .two-facts {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;

    @media screen and (max-width: 960px) {
      flex-direction: column;
    }
  }
  .fact {
    flex: 1;
    margin: 16px;
    background-color: $gray;
    align-self: stretch;
    padding-right: 2rem;
    padding-left: 2rem;
    padding-bottom: 3rem;

    &.light {
      background-color: $lightgray;
    }

    .fact-wrapper {
      display: flex;
      flex-direction: row;



      > * {
        flex: 1;
        margin: 0 16px;
      }

      .icon {
        flex: 0.5;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        svg {
          width: 100%;
        }
      }

      ul {
        margin: 0;
      }

      @media screen and (max-width: 600px) {
        flex-direction: column;
        .fact-content {
          margin-top: 20px;
        }
        .icon {
          svg {
            width: 50%;
          }
        }
      }
    }
  }
}

.download-link {
  height: 300px;
  background-color: var(--green);
  display: flex;
  justify-content: center;
  align-items: center;
}
