body {
  overflow-x: hidden;
}

.TeaserPage2023 {
  margin-top: 40px;

  p {
    max-width: 700px;
  }
}

.TeaserHero {
  height: 54vw;
  background: url("./img/2023/hero.jpg") no-repeat fixed 0 0 / 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  position: relative;

  @media screen and (min-aspect-ratio: 16/9) {
    height: 70vh;
    background-image: url("./img/2023/hero2.jpg");
    background-size: cover;
    background-position: left bottom;
  }

  .RealLogo {
    width: 100%;
    // max-width: 500px;
    height: 30%;
    // max-height: 100px;
    margin: auto auto;

    @media screen and (max-width: 768px) {
      max-width: 50%;
    }
  }

  .TeaserHero__content {
    height: 40%;
    max-height: 400px;
    width: 64%;
    max-width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    color: white;
    position: relative;
    z-index: 0;
    display: flex;
    border: 10px solid;
    border-image-slice: 1;
    border-width: 2px;
    border-image-source: linear-gradient(to left, #25b0f1, #62c584);

    &:before {
      // background: url("./img/2023/hero.jpg") no-repeat 0 0 / 100%;
      content: "";
      backdrop-filter: blur(5px) brightness(0.6);
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;
    }
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

  }

  @media screen and (max-width: 768px) {
    height: 33vh;
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    background-attachment: scroll;
  }
}

.FancyHr {
  height: 2px;
  max-width: 455px;
  margin: 2rem auto;
  background: transparent
    linear-gradient(
      90deg,
      #5cafe7 0%,
      #d1ba6e 9%,
      #82c289 16%,
      #d0ba6e 24%,
      #5bb0e9 33%,
      #7ec28b 46%,
      #61b3d4 58%,
      #d2ba6d 68%,
      #90bf83 80%,
      #5aafea 89%,
      #7dc18e 100%
    )
    0% 0% no-repeat padding-box;
}

.KeynoteCallout {
  background-image: url("./img/2023/john-may-callout-desktop.jpg");
  background-size: cover;
  height: 34.5vw;
  margin: 0 auto;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width: 90%;
  max-width: 1200px;
  max-height: 450px;
  margin-top: 80px;
  margin-bottom: 60px;

  &__content {
    width: 50%;
    flex-grow: 1;
    backdrop-filter: blur(14px) brightness(0.4);
    height: 100%;
    padding: 5% 6% 0 6%;
    color: white;
    box-sizing: border-box;
    font-family: JDSansProCond;

    h2 {
      font-size: 40px;
      font-weight: bold;
      margin: 0;
    }

    h3 {
      font-size: 30px;
      font-family: JDSansProCond;
      font-weight: bold;
      text-align: left;
      margin: 0;
    }

    p {
      text-align: left;
    }
  }

  @media screen and (max-width: 1024px) {
    &__content {
      padding: 3%;
    }
  }

  @media screen and (max-width: 768px) {
    height: auto;
    flex-direction: row;
    margin-top: 40px;
    background-image: url("./img/2023/john-may-callout-mobile.png");
    padding-top: 65vw;
    background-position: top center;
    background-size: 100%;
    background-repeat: no-repeat;

    .KeynoteCallout__content {
      padding: 5% 0;
      color: black;
      backdrop-filter: none;

      &, h2, h3, p {
        text-align: center;
      }
    }
  }
}

.DateTimeCallout {
  background-image: url("./img/2023/sprayer.jpg");
  background-size: cover;
  height: 34.5vw;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 90%;
  max-width: 1200px;
  max-height: 450px;
  margin-top: 60px;

  h2 {
    color: white;
    text-transform: uppercase;
    font-family: JDSansProCond;
    font-size: 72px;
    font-weight: 800;
  }

  &__copy {
    text-align: center;
    margin: 0 auto;
    font-family: JDSansProCond;
    font-weight: 300;
    font-size: 2rem;
    margin-top: 2rem;
  }

  @media screen and (max-width: 768px) {
    height: auto;
    max-height: none;
    background-image: url("./img/2023/sprayer-mobile.png");
    padding-top: 70vw;
    width: 100%;
    background-position: top center;
    background-size: 100%;
    background-repeat: no-repeat;

    &__content,
    &__copy {
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
    }

    h2 {
      color: var(--green);
      text-align: center;
      line-height: 1;
      margin: 0;
    }
  }

  @media screen and (max-width: 370px) {
    h2 {
      font-size: 48px;
    }
  }
}

.big-green {
  font-size: 47px;
  color: var(--green);
  font-weight: bold;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }
}

.PadPanel {
  @media screen and (max-width: 768px) {
    padding: 0 2rem;
  }
}

.PipeJoined {
  .PipeJoined__pipe {
    &::after {
      content: "|";
      margin: 0 1rem;
    }

    @media screen and (max-width: 768px) {
      display: block;


      &::after {
        content: "";
        margin: 0;
      }
    }
  }
}