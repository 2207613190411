.Accolades {
  padding: 4rem 0;
  font-size: 1.5rem;
  background-color: #f5f5f5;

  h2 {
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 4px;
    margin-bottom: 5%;
  }

  .badges {
      display: flex;
      justify-content: space-between;
      max-width: 800px;
      margin: 0 auto;
    > div {
      max-width: 270px;
      flex: 1;
      box-sizing: border-box;
      img {
        height: 240px;
      }
      display: inline-block;
      text-align: center;
      vertical-align: top;

      a {
        color: white;
        font-weight: normal;
        text-decoration: none;
        font-family: JDSansPro;
        text-transform: uppercase;
        letter-spacing: 2px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  @media screen and (max-width: 575px) {

    h2 {
      margin-bottom: 80px;
    }
    .badges {
      flex-direction: column;
      align-items: center;
    }
  }
}
