.ProgramSchedule {
    background-color: white;
    position: relative;
    padding: 0rem 0;
    text-align: center;


    &.showingVideo { z-index: 1 !important; }

    h2 { text-transform: uppercase; font-weight: bold; font-family: JDSansProCond; }

    > h2 { letter-spacing: 5px;}

    a { color: var(--green); }

    p { font-family: JDSansPro;}

    @media screen and (max-width: 768px) {
        > h2 { letter-spacing: 2px; }
        p { width: 80%; margin: 0 auto; }
        padding: 1rem 0;
    }

    .Presenter { font-weight: bold; }
}

.ProgramExpander {
    max-width: 1200px;
    margin: 1rem auto;
    text-align: left;
    position: relative;
    border-left: 3px solid transparent;

    &:nth-child(even) {
        border-color: #415813;
    }
    &:nth-child(odd) {
        border-color: #CEBA71;
    }


    @media screen and (max-width: 1024px) {
        width: 90%;
    }

    .HeaderBar {
        background-color: #eee;
        padding: 1rem;
        text-transform: uppercase;
        letter-spacing: 1px;
        padding-right: 3rem;
        cursor: pointer;
        font-size: 1.25rem;
        position: relative;

        &:hover {
            background-color: #dddddd;
        }

        @media screen and (max-width: 1024px) {
            span {
                font-size: 1rem;
                &.Splitter.Pipe { display: block; height: 0; overflow: hidden; }
            }
        }

        @media screen and (max-width: 440px) {
            span {
                display: block;
                &.Splitter { display: none; }
                font-size: 0.8rem;
            }
        }
    }

    .Body {
        height: 0;
        overflow: hidden;
        opacity: 0;
        transition: opacity 0.3s ease-in;
        max-width: 800px;
        margin: 0 auto;
        h2 { margin: 0;}

        p {
            max-width: 780px;
            margin: 1rem auto;
        }

        &.expanded {
            height: auto;
            padding: 2rem;
            opacity: 1;
            transition: opacity 0.3s ease-in;
        }
    }

    .Expander {
        font-size: 2.5rem;
        color: var(--green);
        font-family: JDSansPro;
        font-weight: normal;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 16px;
        line-height: 1;
        cursor: pointer;

        &::after {
            content: '+';
        }

        &.expanded {
            transform: translateY(-60%);
            &::after {
                content: '–';
            }
        }

        @media screen and (max-width: 768px) {

            // right: 0;
        }
    }
}