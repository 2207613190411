#modal-root {
  z-index: 11;
  position: relative;
  .modal-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: intro-fade 0.5s ease;
    animation-fill-mode: forwards;
    overflow: auto;

    // &.loaded {
    //   opacity: 1;
    //   transition: opacity 0.5s ease;
    // }
  }

  .modal-dialog {
    background-color: white;
    height: auto;
    width: 50%;
    max-width: 600px;
    min-width: 280px;
    font-family: JDSansPro;
    padding: 1rem;
    position: relative;

    &.cto {
      overflow-y: auto;
      max-height: 70vh;
      min-width: 280px;

      @media screen and (max-width: 600px) {
        h2 {
          font-size: 1.25rem;
        }
      }
    }

    &.has-image {
      max-width: 800px;
    }

    &.lightbox {
      max-width: none;
      width: auto;
      padding: 0;

      &.has-image,
      &.has-video {
        max-width: 60%;
        max-height: 100vh;

        img,
        video {
          max-width: 100%;
          display: block;
          max-height: 80vh;
        }

        @media screen and (max-width: 768px) {
          max-width: none;
        }
      }
    }
    a.download-button {
      height: 30px;
      width: 30px;
      position: absolute;
      background-color: rgba(#666, 0.8);
      display: block;
      padding: 12px;
      bottom: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: #666;
      }

      svg {
        height: 25px;
      }
    }

    h2 {
      // color: var(--green);
      text-transform: uppercase;
      // text-align: center;
      font-size: 2rem;
      margin: 0 0 10px 0;
      line-height: 1;
    }

    &.left-heading h2 {
      color: black;
      text-align: left;
    }
  }

  .close-button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    font-size: 5rem;
    line-height: 0.5;
    color: white;
    cursor: pointer;
    font-family: JDSansPro;
    z-index: 1;
    @media screen and (max-width: 768px) {
    }
  }

  .modal-image {
    position: absolute;
    left: 1rem;
    top: 1rem;
    // bottom: 0;
    width: 250px;
    height: 250px;
    background-size: cover;
    background-position: center;
    z-index: 2;
  }

  .modal-content {
    position: relative;
    &.has-image {
      padding-left: 270px;
      min-height: 252px;
    }

    img {
      max-width: 200px;
    }

    a { color: var(--green); }
  }

  @media screen and (max-width: 768px) {
    .modal-dialog {
      padding-top: 60px;
      width: 100%;
      h2 {
        text-align: center;
      }
    }

    .modal-image {
      margin: 0 auto;
      left: auto;
    }


    .modal-container:not(.lightbox) {
      align-items: flex-start;
      .close-button { color: black; padding-bottom: 3px; background-color: white;}
    }

    .modal-content.has-image {
      padding: 0 0 0 0;
    }

    .modal-image {
      top: 0;
      background-size: contain;
      background-repeat: no-repeat;
      position: relative;
    }
  }
}

@keyframes modal-fade {
  from {
    opacity: 0;
    z-index: 0;
  }

  to {
    opacity: 1;
    z-index: 1;
  }
}
