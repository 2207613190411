.HeroLiveStream {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50vh;
    min-height: 600px;
    background-image: url(./img/2022/hero-redirect.jpg);
    background-size: cover;
    background-position: center;
    text-align: center;
    font-family: JDSansProCond;
    color: white;

    .HeroLiveStream__content {
        margin-top: -50px;
    }

    h2 {
        font-size: 2.5rem;
        font-weight: 700;
        color: #fff;
        margin-bottom: 0.5rem;
        text-transform: uppercase;
    }

    h3 {
        font-size: 1.5rem;
        font-weight: 700;
        color: #fff;
        margin: 0;
        // margin-bottom: 1rem;
        text-transform: uppercase;
    }

    a {
        display: inline-block;
        background-image: url(./img/2022/yt-button-idle.png);
        background-size: contain;
        background-repeat: no-repeat;
        height: 150px;
        width: 150px;
        margin-top: 20px;;

        &:hover {
            background-image: url(./img/2022/yt-button-hover.png);
        }
    }

    @media (max-width: 768px) {
        h2 {
            font-size: 2.5rem;
            padding: 1rem;
        }
        a { width: 100px; margin: 0; }
    }
}