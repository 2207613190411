.MediaResources {
  background-color: #ddd;

  h1 {
    text-transform: uppercase;
    text-align: center;
    font-size: 4rem;
    margin-top: 0;

    @media screen and (max-width: 768px) {
      font-size: 2.5rem;
    }
  }

  h2 {
    text-transform: uppercase;
    text-align: center;
    font-size: 2rem;
  }

  .tabs {
    text-align: center;
    margin-bottom: 3rem;
    display: flex;

    .OutlineButton {
      margin: 0 1%;
      flex: 1;
      font-size: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @media screen and (max-width: 768px) {
      .OutlineButton {
        font-size: 0.8rem;
      }
    }
    @media screen and (max-width: 625px) {
      flex-direction: column;
      .OutlineButton {
        margin: 0.5rem;
      }
    }
  }

  .img-wrapper {
    width: 33%;
    height: 270px;
    display: inline-block;
    box-sizing: border-box;
    border: 2px solid #ddd;
    cursor: pointer;
    overflow: hidden;
    position: relative;

    @media screen and (max-width: 575px) {
      width: 100%;
    }

    .img-thumb {
      background-size: cover;
      background-position: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: transform 0.6s ease;

      &.presenters {
        background-position: top;
      }
    }

    .thumb-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(black, 0.5);
      transition: opacity 0.6s ease;
      opacity: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 1.25rem;
      font-weight: bold;
      text-transform: uppercase;
      span {
        &::before {
          content: "";
          height: 30px;
          width: 30px;
          background-image: url(./img/Magnifying-icon.svg);
          display: inline-block;
          position: relative;
          top: 9px;
          left: -3px;
        }
      }
    }

    &:hover {
      .img-thumb {
        transform: scale(1.1);
        transition: transform 0.6s ease;
      }

      .thumb-overlay {
        transition: opacity 0.6s ease;
        opacity: 1;
      }
    }
  }

  .images-grid {
    text-align: center;
  }

  .ImageResources,
  .VideoResources {
    max-width: 1600px;
    margin: 0 auto;
    min-height: 50vh;
  }

  .filters {
    color: #aaa;
    text-align: center;
    font-weight: bold;
    font-size: 1.25rem;
    margin-bottom: 2rem;
    text-transform: uppercase;

    @media screen and (max-width: 515px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    div {
      display: inline-block;
      margin: 0 1.5rem;
      cursor: pointer;

      &.active {
        border-top: 2px solid var(--green);
        color: black;
      }

      @media screen and (max-width: 515px) {
        margin: 0.5rem;
        font-size: 0.9rem;
      }

      @media screen and (max-width: 360px) {
        margin: 0.3rem;
        font-size: 0.8rem;
      }
    }
  }

  .no-images {
    text-align: center;
    font-size: 2.25rem;
    color: var(--green);
    margin-top: 5rem;
    font-weight: bold;
  }

  .Magazine {
    background-color: white;
    padding: 4rem;

    p {
      font-size: 1.5rem;
      margin: 0 auto;
    }

    > div {
      text-align: center;
      color: black;
      display: inline-block;
      vertical-align: middle;

      box-sizing: border-box;

      &:first-child {
        width: 60%;
        padding: 0 3rem 0 6rem;
      }

      &:last-child {
        width: 40%;
        text-align: left;
        padding-left: 5%;
      }

      img {
        max-width: 100%;
        max-height: 400px;
      }
    }

    @media screen and (max-width: 768px) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 4rem 1rem;
      div {
        flex: 1;

        &:first-child {
          padding: 0;
          order: 2;
          width: auto;
        }

        &:last-child {
          order: 1;
          text-align: center;
          padding: 0;
          width: auto;
        }

        img {
          max-width: 100%;
          max-height: 400px;
        }
      }
    }

    .OutlineButton {
      margin-top: 2rem;
    }
  }
}

.MediaResourcesFooter {
  color: white;

  .Accolades {
    background-image: url(./img/CES-Award_BG.jpg);
    background-size: cover;
    padding: 4rem 0;
    font-size: 1.5rem;

    > div {
      padding: 0 12%;
      box-sizing: border-box;
      &:not(:last-child) {
        border-right: 1px solid rgba(white, 0.7);
      }
      img {
        height: 150px;
      }
      width: 33%;
      display: inline-block;
      text-align: center;
      vertical-align: top;

      a {
        color: white;
        font-weight: normal;
        text-decoration: none;
        font-family: JDSansPro;
        text-transform: uppercase;
        letter-spacing: 2px;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    @media screen and (max-width: 768px) {
      font-size: 1rem;

      > div {
        padding: 20px;
      }
    }

    @media screen and (max-width: 575px) {
      > div {
        display: block;
        width: auto;
        margin: 4rem auto;
        padding: 0;
        &:not(:last-child) {
          border-right: none;
        }
      }
    }
  }

  .Contacts {
    background-color: black;
    font-family: JDSansPro;

    h4 {
      font-size: 2rem;
      margin: 0;
    }

    > div {
      box-sizing: border-box;
      width: 33%;
      display: inline-block;
      padding: 2rem 10%;
    }

    @media screen and (max-width: 768px) {
      > div {
        display: block;
        padding: 4rem auto;
        width: auto;
        text-align: center;
      }
    }
  }

  .Links {
    color: black;
    text-align: center;
    .OutlineButton {
      margin: 4rem 2rem;
    }
    padding: 3rem 0;

    @media screen and (max-width: 600px) {
      .OutlineButton {
        margin: 0.5rem 0;
      }
    }
  }
}
