@font-face {
    font-family: JDSansPro;
    src: url(./pro/JDSansPro-Medium.ttf);
}

@font-face {
    font-family: JDSansPro;
    font-weight: bold;
    src: url(./pro/JDSansPro-Bold.ttf);
}

@font-face {
    font-family: JDSansPro;
    font-weight: 500;
    src: url(./pro/JDSansPro-Semibold.ttf);
}

@font-face {
    font-family: JDSansPro;
    font-weight: 300;
    src: url(./pro/JDSansPro-Light.ttf);
}

@font-face {
    font-family: JDSansProCond;
    src: url(./cond/JDSansPro-CondBook.ttf);
}

@font-face {
    font-family: JDSansProCond;
    src: url(./cond/JDSansPro-CondSemibold.ttf);
    font-weight: bold;
}

@font-face {
    font-family: JDSansProCond;
    src: url(./cond/JDSansPro-CondBold.ttf);
    font-weight: 800;
}